import React, { useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
// import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Button from "@material-ui/core/Button";

import SideBar from '../components/Sidebar'


const NavBar = ({ search, ...props }) => {
  const [open, setOpen] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [selected, setSelected] = useState('')
  const classes = useStyles();

  const handleSearch = e => {
    props.setSearch(e.target.value);
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.root}>
        <Toolbar>
          <Typography className={classes.logo} variant="h6" noWrap>
            Plenti
          </Typography>
          <div className={classes.grow} />
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Szukaj..."
              value={search}
              onChange={handleSearch}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div className={classes.grow} style={{ width: 65 }} />
        </Toolbar>
        <SecondRow>
          <StyledButton
            id='location-button'
            onClick={() => setOpen(true)}
            disableRipple
            disableFocusRipple
          >
            <LocationOnIcon fontSize="large" />
          </StyledButton>
          <label htmlFor='location-button'>{selected === "" ? "Wybierz lokalizację" : selected}</label>
          <SideBar open={open}
            setOpen={setOpen}
            addressList={addressList}
            setAddressList={setAddressList}
            selected={selected}
            setSelected={setSelected}
          />
        </SecondRow>
      </AppBar>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    background: "#fdfdfd",
    color: "#6e6e6e"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  logo: {
    paddingLeft: 13,
    display: "none",
    color: "#000000",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    border: "1px solid #adadad",
    borderRadius: 5
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch"
    }
  }
}));


const StyledButton = styled(Button)`
  svg {
    color: #b3b3b3;
    transition: color 300ms ease-out;
  }

  &:hover {
    background-color: transparent;
    svg {
      color: #2994d6;
      transition: color 300ms ease-in;
    }
  }
`;

const SecondRow = styled.div`
  margin: 0 20px;
  padding: 10px 0;
  border-top: 1px solid #e0e0e0;
  
  & > label{
    cursor: pointer;
  }
`;


export default NavBar