import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Skeleton from "@material-ui/lab/Skeleton";
import ItemSlider from "../components/ItemSlider";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useTransition, animated } from 'react-spring'

const ItemsList = ({ search }) => {
  const [items, setItems] = useState([]);
  const [isFetching, setIsFetching] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  useEffect(() => {
    const fetchItems = async () => {
      setIsFetching(true);
      const res = await axios.get("https://dev.plenti.app/api/products/all");
      console.log(res);
      setItems(res.data);
    };

    fetchItems()
      .then(() => setIsFetching(false))
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    const results = items.filter(item =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );
    setSearchResults(results);
  }, [search, items]);

  const transitions = useTransition(!isFetching, null, {
    initial: {
      transform: 'translate3d(0%, 0%,0)',
      opacity: 1
    },
    from: {
      transform: 'translate3d(0,20%,0)',
      opacity: 0
    },
    enter: {
      transform: 'translate3d(0%, 0%,0)',
      opacity: 1
    }
  })

  return (
    <StyledContainer>
      <Typography style={{ marginLeft: 20 }} variant="h3" component="h2">
        Produkty
      </Typography>
      {isFetching ? (
        <SkeletonGroup>
          <Skeleton variant="rect" animation="wave" height={250} width="47%" />
          <Skeleton variant="rect" animation="wave" height={250} width="47%" />
          <Skeleton variant="text" animation="wave" height={30} width="100%" />
          <Skeleton variant="text" animation="wave" height={30} width="25%" />
          <Skeleton variant="text" animation="wave" height={30} width="70%" />
          <Skeleton variant="rect" animation="wave" height={150} width="100%" />
        </SkeletonGroup>
      ) : (
          transitions.map(({ item, key, props }) =>
            item && <animated.div key={key} style={props}>
              <ItemSlider searchResults={searchResults} />
            </animated.div>
          )

        )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  margin-top: 20px;
`;

const SkeletonGroup = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
`;

export default ItemsList;
