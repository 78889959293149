import React from 'react'
import styled from 'styled-components'

const Radio = ({ value, checked, ...props }) => {
    return (
        <Wrap>
            <RadioInput id={value} type="radio" value={value} checked={value === checked} onChange={props.onChange} />
            <label htmlFor={value}>{value}</label>
        </Wrap>
    )
}

const Wrap = styled.div`

 &:hover{
        background-color:#dbdbdb;
        transition: background-color 300ms;
    }
`

const RadioInput = styled.input`
display: none;
&+label{
    cursor: pointer;
    padding: 30px 50px;
    display: block;
    align-self: center;
    font-size: 20px;
    font-weight: 500;
    color: #26454F;
}
&:checked+label{
    cursor: default;
    background-color: #319795;
    color: white;
    transition: all 300ms;
}
`

export default Radio