import React, { Component } from "react";
import styled from "styled-components";
import Slider from "react-slick";
// import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ListItem from "./ListItem";

export default class ItemSlider extends Component {
  render() {
    const { searchResults } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: searchResults.length < 4 ? searchResults.length : 4,
      slidesToScroll: searchResults.length < 4 ? searchResults.length : 4
    };
    return (
      <SliderWrap>
        <Slider {...settings}>
          {searchResults.map((item, index) => (
            <ListItem key={index} item={item} />
          ))}
        </Slider>
      </SliderWrap>
    );
  }
}

const SliderWrap = styled.div`
  .slick-list{
    img{
      filter: none;
      transition: all 200ms ease-out;
    }
    &:hover{
        img{
          filter: grayscale(100%);
          transition: filter 500ms;
          &:hover{
            transform: scale(1.1);
            filter: none;
            transition: all 400ms;
          }
          transition: all 400ms ease-out;
        }
      }
  }
 

  max-width: 95vw;
  margin: 40px auto;
  .slick-prev,
  .slick-next {
    top: -20px;
    &::before {
      /* font-family: "Roboto", "sans-serif"; */
      color: black;
      &:hover {
        color: black;
      }
    }
  }
  .slick-prev {
    left: unset;
    right: 65px;
    /* &::before {
      content: "<";
    } */
  }
  .slick-next {
    right: 25px;
    /* &::before {
      content: ">";
    } */
  }
`;
