import React, { useState } from "react";
import ItemsList from "./containers/ItemsList";
import Nav from "./containers/Nav";

function App() {
  const [search, setSearch] = useState("");

  return (
    <div>
      <Nav search={search} setSearch={setSearch} />
      <ItemsList search={search} />
    </div>
  );
}

export default App;
