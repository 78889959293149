import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";


import { price } from "../utils/priceFormatter";

const useStyles = makeStyles({
  root: {
    margin: 20,
    maxWidth: 345,
    borderRadius: 10
  },
  media: {
    height: 250,
    objectFit: "contain"
  },
  chip: {
    marginLeft: 10
  }
});

const ListItem = ({
  item,
  ...props
}) => {
  const classes = useStyles();

  return (<Card className={classes.root}>
    <CardActionArea>
      <CardMedia
        component="img"
        className={classes.media}
        image={item.image_url}
        title={item.name}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {item.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {price(item.price)}
          {item.in_warehouse || (
            <Chip
              className={classes.chip}
              label="brak w magazynie"
              size="small"
            />
          )}
        </Typography>
      </CardContent>
    </CardActionArea>
    <CardActions>
      <Button size="small" color="primary" disabled={!item.ordering_active}>
        Zamów
        </Button>
    </CardActions>
  </Card>
  );
};

export default ListItem;
