import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Radio from './Radio'

const Sidebar = ({ open, addressList, selected, ...props }) => {
    const [address, setAddress] = useState('');


    const handleChange = e => {
        setAddress(e.target.value);
    }

    const handleSubmit = e => {
        const { value } = e.target;
        if (e.keyCode === 13) {
            props.setAddressList(prevState => {
                return [
                    ...prevState,
                    value
                ]
            });
            setAddress('');
        }
    }

    const onRadioChange = (e) => {
        props.setSelected(e.target.value);
        props.setOpen(false)
    }

    return (
        <StyledDrawer open={open} onClose={() => props.setOpen(false)}>
            <Wrap>
                <Typography style={{ margin: '0 20px' }} variant="h5" component="h4">
                    Wybierz adres
                 </Typography>
                <Button onClick={() => props.setOpen(false)}>
                    <HighlightOffIcon />
                </Button>
            </Wrap>
            <Input
                placeholder="Dodaj adres..."
                value={address}
                onChange={handleChange}
                onKeyDown={handleSubmit}
            />
            <Addresses>
                <form>
                    {addressList.length === 0 ? <span>Pusto...</span> :
                        addressList.map((item, index) => (<Radio key={index} value={item} checked={selected} onChange={onRadioChange} />))}
                </form>
            </Addresses>
        </StyledDrawer>
    )
}

const StyledDrawer = styled(Drawer)`
.MuiDrawer-paperAnchorLeft{
    width: 400px;
}
`

const Wrap = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
padding-top: 20px;
`

const Addresses = styled.div`
display: flex;
flex-direction: row;
margin: 30px 0;

form{
    width: 100%;
}

span{
    text-align: center;
    display: block;
    font-weight: 400;
    color: #6e6e6e;
    font-size: 17px;
}
`

const Input = styled(InputBase)`
margin-left: 20px;
`

export default Sidebar